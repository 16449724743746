/* src/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* ---------- Global fixes ---------- */
thead {
  white-space: nowrap;
}

.top-sidebar-calc {
  top: 100%;
  transform: translateY(-100%);
}

.Toastify__toast-container {
  width: fit-content;
  max-width: 60vw;
}

@layer base {
  * {
    @apply box-border;
    margin: 0;
    padding: 0;
    line-height: calc(1em + 0.5rem);
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    @apply font-dm-sans bg-body;
  }

  img,
  picture,
  video,
  svg {
    @apply block max-w-full select-none;
  }

  button {
    @apply inline-block p-0 border-none bg-none cursor-pointer;
  }

  .toggle-checkbox:checked {
    right: 0;
    border-color: #68d391;
  }

  .toggle-checkbox:checked + .toggle-label {
    background-color: #68d391;
  }

  .toggle-checkbox {
    right: 12px;
    transition: right 0.2s ease-in-out;
  }

  .toggle-label {
    width: 44px;
    height: 22px;
    display: block;
  }
}

@layer components {
  .custom-checkbox {
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid;
    border-radius: 4px;
    position: relative;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
  }

  h2 {
    letter-spacing: 0.48px;
  }
}

#login-background {
  background-image: url(../public/img/login-background-img.png);
}

.login-shadow {
  box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.08),
    0px 0px 1px 0px #fefcfd inset;
}

/* ------------- Selected/Active Class ------------- */

.inactive-tab {
  color: #747474;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}

.active-tab {
  position: relative;
  font-weight: 700;
  cursor: pointer;
  user-select: none;
}

.active-tab::after {
  content: '';
  height: 4px;
  width: 125%;
  background-color: #33d76f;
  position: absolute;
  left: -12.5%;
  bottom: calc(-0.5rem - 2px);
}

.underlined-brand-tab {
  position: relative;
}

.underlined-brand-tab::after {
  content: '';
  height: 1px;
  width: calc(100% + 100px);
  background-color: #e3e3e3;
  position: absolute;
  left: -50px;
  bottom: -3px;
  margin-bottom: 3px;
}

@media (max-width: 1280px) {
  .underlined-brand-tab::after {
    content: '';
    height: 1px;
    width: 300px;
    background-color: #e3e3e3;
    position: absolute;
    left: calc(50% - 150px);
    bottom: -3px;
    margin-bottom: 3px;
  }
}

/* ------------- Custom Icons ------------- */
#x-icon {
  cursor: pointer;
  background-image: url(../public/img/x.svg);
  width: 1.65rem;
  height: 1.65rem;
  background-size: cover;
}

.custom-arrowdown {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
